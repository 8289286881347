import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import logo from "./assets/img/logo.svg";
import artigiani from "./assets/img/form.jpg";
import divider from "./assets/img/divider.png";
import divider2 from "./assets/img/divider2.png";
import google from "./assets/img/google.svg";
import apple from "./assets/img/apple.svg";
import facebook from "./assets/img/facebook.svg";
import instagram from "./assets/img/instagram.svg";
import discord from "./assets/img/discord.svg";
import luca from "./assets/img/luca.jpg";
import davide from "./assets/img/davide.jpg";
import elia from "./assets/img/elia.jpg";
import damian from "./assets/img/damian.jpg";
import { useRef } from "react";
import gsap from "gsap";
import { useGSAP } from "@gsap/react";
import { ScrollTrigger } from "gsap/ScrollTrigger";

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

document.addEventListener(
  "DOMContentLoaded",
  function () {
    setTimeout(function () {
      document.querySelector("body").classList.add("loaded");
    }, 1000);
  },
  false
);

function App() {
  gsap.registerPlugin(useGSAP);
  gsap.registerPlugin(ScrollTrigger);

  const gsap_container = useRef();

  useGSAP(() => {
    var headerTl = gsap.timeline({
      scrollTrigger: {
        trigger: ".hea",
        markers: false,
        start: "98% top",
        end: "bottom bottom",

        toggleActions: "play none reverse  none",
        markers: false,
        onEnter: () => {
          headerTl.timeScale(1.0);

          console.log("enter");
        },
        onEnterBack: () => {
          headerTl.timeScale(2.0);
          headerTl.reverse();
          console.log("enter back");
        },
      },
    });

    headerTl.to("header", {
      background: "#ffffff",
      boxShadow: "#3838384d 0px 0px 7px",
      duration: 0.3,
    });
    headerTl.to(".logo_header", {
      delay: -0.2,
      opacity: 1,
      duration: 0.3,
    });
    headerTl.to(" #hamburger span", {
      delay: -0.4,
      background: "#000000",
      duration: 0.4,
    });

    var tlmenu = new gsap.timeline({
      paused: true,
    });
    tlmenu.to("#burger-menu span", {
      rotation: 90,
      transformOrigin: "center",
      duration: 0.7,
    });

    var tl = new gsap.timeline({
      paused: true,
    });
    /*tl.to('.logo-hero', 1.2, {
          opacity:1,
          
          ease: Power4.easeOut
      })*/

    tl.to("#menu", {
      duration: 0.7,
      autoAlpha: 1,
      ease: "power3.inOut",
    });
   
    tl.to(
      "#menu ul li",
      {
        duration: 1,
        opacity: 1,
        y: 0,
        ease: "power3.inOut",
        stagger: 0.1,
      },
      "<0.1"
    );

    var menuOpen = false;
    var body = document.querySelector("body");
    var closemenu = document.querySelectorAll("#burger-menu, .menu_link");
    for (let i = 0; i < closemenu.length; i++) {
      closemenu[i].addEventListener("click", function () {
        if (!menuOpen) {
          body.classList.add("hidden");
          tl.play();
          tlmenu.play();
          menuOpen = true;
        } else {
          body.classList.remove("hidden");
          tl.timeScale(1.5).reverse();
          tlmenu.timeScale(1.5).reverse();
          menuOpen = false;
        }
      });
    }

    gsap.utils.toArray(".fadin").forEach((fadin, i) => {
      const fad = fadin.querySelector(".fadin");

      ScrollTrigger.create({
        trigger: fadin,

        toggleClass: "fade_active",
        start: "10% 90%",
        stagger: 0.15,
        // this toggles the class again when you scroll back up:
        toggleActions: "play none none none",
        // this removes the class when the scrolltrigger is passed:
        // once: true,
        //markers: true,
        scrub: false,
        markers: false,
      });
    });
  });

  return (
    <div ref={gsap_container} className="gsap_container">
      <div id="preloader" className="preloader">
        <img className="logopreloader" src={logo} />
      </div>
      <header className="container-fluid ">
        <div className="row">
          <div className="col-3 align-self-center">
            <p></p>
          </div>
          <div className="col-6 text-center">
            <img className="logo_header" src={logo} alt="" />
          </div>
          <div className="col-3 div_burger">
            <div type="button" id="burger-menu">
              <span></span>
            </div>
          </div>
        </div>
      </header>
      <div id="menu">
        <ul>
          <li>
            <a className="menu_link grad" href="#mission">
              Mission
            </a>
          </li>
          <li>
            <a className="menu_link grad" href="#team">
              Team
            </a>
          </li>
          <li>
            <a className="menu_link grad" href="#contatti">
              Contact
            </a>
          </li>
        </ul>
      </div>
      <section className="container-fluid hea full_height">
        <img className="divider" src={divider} alt="" />
        <img className="logo_hero pb-1" src={logo} alt="" />
        <h1 className="text-center">International Live <br />Role-Playing Community for Associations and Players</h1>
        <div className="div_btn">
          <a className="btn1" href="#mission">
            <span>Who We Are</span>
          </a>
          <a className="btn2" href="#contatti">
            <span>Contact Us</span>
          </a>
        </div>
      </section>

      {/*
      <section id="" className="container-fluid py-5 stat">
        <div className="row text-center mille">
          <p className="pre_title">SUPPORTERS</p>
          <h3 className="fadin">Una grande community</h3>
          <p className=" fadin">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
        </div>
        <div className="grid_stat mille pt-5 text-center">
          <div className="stat_card">
            <h3 className="numero">4000</h3>
            <p>Associazioni iscritte</p>
          </div>
          <div className="stat_card">
            <h3 className="numero">80000</h3>
            <p>Giocatori iscritte</p>
          </div>
          <div className="stat_card">
            <h3 className="numero">16000</h3>
            <p>Eventi creati</p>
          </div>
        </div>
        <div className="row text-center mille">
          <div className="col-12 pt-5">
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
          </div>
          <div className="down_box mt-5">
            <h3 className="fadin">Scaricala ora</h3>
            <div className="div_down">
              <a href="#">
                <img className="down_img" src={google} alt="" />
              </a>
              <a href="#">
                <img className="down_img" src={apple} alt="" />
              </a>
            </div>
          </div>
        </div>
      </section>
 
      <section id="" className="container-fluid py-5 vantaggi_a">
        <img className="divider" src={divider} alt="" />
        <img className="divider divider2" src={divider2} alt="" />
        <div className="row text-center mille invert">
          <div className="col-md-6 text-left pt-3">
            <p className="pre_title">IL TUO PARTNER</p>
            <h3 className="fadin">Vantaggi per Associazione</h3>

            <p className=" fadin">
              Vogliamo dare la possibilità alle associazioni di farsi conoscere
              e poter crescere più facilmente attraverso la visibilità
              all’interno della nostra App, portando così il gioco di ruolo
              verso nuovi orizzonti. Alcune delle tante funzionalità che potrete
              avere a disposizione nell’app come:
            </p>
            <ul>
              <li>
                Visibilità per la vostra associazione e per i vosti eventi
              </li>
              <li>Creare e gestione autonoma per i vostri eventi</li>
              <li>Ricevere feedback</li>
            </ul>
            <a className="btn1 mt-3" href="">
              <span>Iscrivimi adesso</span>
            </a>
          </div>
          <div className="col-md-6">
            <img
              className="fadin w-100 app"
              src="/assets/img/app1.jpg"
              alt=""
            />
          </div>
        </div>
      </section>
      <section id="" className="container-fluid py-5 partner">
        <div className="row text-center mille">
          <p className="pre_title">PRETITOLO</p>
          <h3 className="fadin">banner partnership</h3>
          <p className=" fadin">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.
          </p>
          <div className="grid_part pt-4">
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
          </div>
        </div>
      </section>

      <section id="" className="container-fluid pb-5 vantaggi_g">
        <img className="divider divider2" src={divider2} alt="" />
        <img className="divider" src={divider} alt="" />
        <div className="row text-center mille">
          <div className="col-md-6 ">
            <img
              className="fadin w-100 app"
              src="/assets/img/app2.jpg"
              alt=""
            />
          </div>
          <div className="col-md-6 text-left  pt-3">
            <p className="pre_title">PRETITOLO</p>
            <h3 className="fadin">Vantaggi per giocatore</h3>

            <p className=" fadin">
            Esplora nuovi eventi in Italia, scopri associazioni locali e partecipa con facilità: tutto in un'unica app. Iscriviti, ricerca, partecipa e ricevi aggiornamenti direttamente sul tuo profilo personale.
            </p>
            <ul>
              <li>Scoprire le associazioni nelle vicinanza</li>
              <li>Iscriversi agli eventi online</li>
              <li>Possibilità di tesserarsi online ad una associazione</li>
            </ul>
          </div>
        </div>
      </section>

      <section id="" className="container-fluid py-5 pro">
        <div className="row text-center mille">
          <div className="down_box my-5">
            <h3 className="fadin">Scaricala ora</h3>

            <div className="div_down">
              <a href="#">
                <img className="down_img" src="/assets/img/google.svg" alt="" />
              </a>
              <a href="#">
                <img className="down_img" src="/assets/img/apple.svg" alt="" />
              </a>
            </div>
          </div>
          <p className="pre_title">PRETITOLO</p>
          <h3 className="fadin">Pacchetti PRO</h3>
          <p className=" fadin">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
          </p>
        </div>
        <div className="grid_stat mille pt-5 text-center">
          <div className="pro_card">
            <h3 className=" py-3 card_title">Pc. 1</h3>
            <div className="pro_card_cont">
              <p className="price"> € 50,00</p>
              <p>Mensili</p>
              <p className=" fadin">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua.{" "}
              </p>
              <ul>
                <li>Vantaggio 1</li>
                <li>Vantaggio 2</li>
                <li>Vantaggio 3</li>
              </ul>
              <a className="btn1" href="">
                <span>Prova ora</span>
              </a>
            </div>
          </div>
        </div>
      </section>

      <section id="" className="container-fluid py-5 artigiani">
        <img className="artig_back" src={artigiani} alt="" />
        <img className="divider divider2" src={divider2} alt="" />
        <img className="divider" src={divider} alt="" />
        <div className="row text-center mille">
          <div className="col-md-6">
            <img className="w-100" src="/assets/img/placeholder.png" alt="" />
          </div>
          <div className="col-md-6 text-left">
            <p className="pre_title">PRETITOLO</p>
            <h3 className="fadin">Artigiani</h3>
            <p className=" fadin">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <form className="pt-4">
              <div className="form-group">
                <label htmlFor="exampleInputEmail1">Email address</label>
                <input
                  type="email"
                  className="form-control"
                  id="exampleInputEmail1"
                  aria-describedby="emailHelp"
                  placeholder="Enter email"
                />
                <small id="emailHelp" className="form-text text-muted">
                  We'll never share your email with anyone else.
                </small>
              </div>
              <div className="form-group">
                <label htmlFor="exampleInputPassword1">Password</label>
                <input
                  type="password"
                  className="form-control"
                  id="exampleInputPassword1"
                  placeholder="Password"
                />
              </div>
              <div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="exampleCheck1"
                />
                <label className="form-check-label" htmlFor="exampleCheck1">
                  Check me out
                </label>
              </div>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </form>
          </div>
        </div>
      </section>
 */}

      <section id="mission" className="container-fluid py-5 stat">
        <div className="row text-center mille">
         
          <h3 className="fadin grad">Mission</h3>
          <p className=" fadin">
          We are committed to promoting and spreading the culture of
            role-playing, creating an inclusive and stimulating environment for
            all fans. We aim to foster creativity, the
            collaboration and fun, encouraging storytelling
            interactive and social skills development. We want to build
            a supportive and passionate community, where everyone can explore
            fantastic worlds, live epic adventures and create lasting ties.
          </p>
        </div>
      </section>

      <section id="team" className="container-fluid py-5 team">
        <img className="divider divider2" src={divider2} alt="" />
        <img className="divider" src={divider} alt="" />
        <div className="row text-center mille pb-5">
          <h3 className="fadin grad">Team</h3>
          <p className=" fadin">
          Our team represents a concentration of technical skills
            solid, spanning a wide range of fields and disciplines in the
            world of technology and design combining creativity and passion
            for innovation.
          </p>
        </div>

        <div className="row mille d-flex">
          <div className="team_card">
            <div className="card_img">
              <img className="team_img fadin" src={luca} alt="" />
              <h3 className="pb-0 fadin">Luca Ferrari</h3>
              <p className=" fadin">Founder & Mobile Developer</p>
            </div>
            <div className="card_content fadin">
              <p>
                Luca Ferrari is an experienced computer programmer and a leader
                technology with a passion for innovation and resolution
                problems. With over 8 years of experience in the field, Luca
                worked in the development of software solutions for smartphones
                at the forefront for different companies. Luca took a course
                as Application Mobile Developer at IFOA from 2014 to 2015 and
                has obtained a certificate as a programmer analyst in the
                region Emilia-Romagna in 2016. In leisure, Luca loves the
                role-playing, collecting, watching movies and playing games
                video games with friends.
              </p>
            </div>
          </div>

          <div className="team_card">
            <div className="card_img">
              <img className="team_img fadin" src={davide} alt="" />
              <h3 className="pb-0 fadin">Davide Dinoi</h3>
              <p className=" fadin">Founder & Mobile Developer & UX/UI Design</p>
            </div>
            <div className="card_content fadin">
              <p>
                Davide Dinoi is a Frontend Developer with a passion for
                mobile app. With over 5 years of industry experience
                technology, but always passionate about computer science. Davide
                worked in the development of software solutions for smartphones
                cutting edge for different companies. Davide loves DIY and
                plant care.
              </p>
            </div>
          </div>

          <div className="team_card">
            <div className="card_img">
              <img className="team_img fadin" src={damian} alt="" />
              <h3 className="pb-0 fadin">Damian Marti</h3>
              <p className=" fadin">Co-founder & Web Designer & Marketers</p>
            </div>
            <div className="card_content fadin">
              <p>
                Damian Marti is a Web Designer and communication expert
                digital. With over 5 years of experience as a freelancer has
                created its own Innovalis reality, and follows companies and free
                professionals in the various processes of digital communication, from
                website to social, from placement to sale. It’s a
                chronic thinker!
              </p>
            </div>
          </div>
        </div>
      </section>

      {/*
      <section id="" className="container-fluid py-5 social">
        <div className="row text-center mille">
          <h3 className="fadin">Link social</h3>
          <div className="div_social">
            <a href="#">
              <img className="social_img" src={facebook} alt="" />
            </a>
            <a href="#">
              <img className="social_img" src={instagram} alt="" />
            </a>
            <a href="#">
              <img className="social_img" src={discord} alt="" />
            </a>
          </div>
        </div>
      </section>
      */}

      <footer id="contatti">
        <div className="row mille bb mb-4 pb-4">
          <div className="col-12 pb-4">
          <h3 className="fadin grad">Contact</h3>
          </div>
       
          <div className="d-flex seicento">
            <div className="d-flex flex-column col-md-12 fadin">
              <h6>Email</h6>
              <p><a href="mailto:info@rolesaga.com" className="grad">info@rolesaga.com</a></p>
            </div>
            
          </div>
        </div>
        <div className="row w-100 mille">
          <p className="mb-0 p-0">
            © 2024 Role Saga di Ferrari Luca e C. sas. All rights reserved
          </p>
          <p className="mb-1">P.iva 03078050345</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
